const encodeFormData = data => {
  const formData = new FormData()
  Object.keys(data).forEach(k => {
    formData.append(k, data[k])
  })
  return formData
}

const scrollTo = el => {
  if (typeof window !== 'undefined') {
    const offset = el.offsetTop
    window.scroll({ top: offset, left: 0, behaviour: 'smooth' })
  }
}

const scrollToTop = e => {
  e.preventDefault()

  if (typeof window !== 'undefined') {
    window.scroll({ top: 0, left: 0, behaviour: 'smooth' })
  }
}

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export { encodeFormData, scrollTo, scrollToTop, validateEmail }

import React from 'react'
import { Link } from 'gatsby'

import { scrollTo } from '../helpers'

const Navlink = ({ className, elementId, text, ...props }) => {
  function scrollToEl(e, id) {
    e.preventDefault()

    if (typeof document !== 'undefined') {
      const el = document.getElementById(id)

      return el && scrollTo(el)
    }
  }

  return elementId ? (
    <a
      className={`font-heading text-white p-2 sm:p-3 uppercase inline-block hover:underline ${className}`}
      onClick={e => scrollToEl(e, elementId)}
      href={`#${elementId}`}
      {...props}
    >
      {text}
    </a>
  ) : (
    <Link
      activeClassName="underline"
      className={`font-heading p-2 sm:p-3 uppercase inline-block hover:underline text-white ${className}`}
      {...props}
    >
      {text}
    </Link>
  )
}

Navlink.defaultProps = {
  className: '',
  elementId: null,
}

export { Navlink }

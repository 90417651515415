import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { FaEnvelope } from 'react-icons/fa'

import { Logo } from './svg/Logo'
import { Navlink } from './Navlink'

const Header = () => (
  <div>
    <header className="py-4 md:py-0 px-2 bg-gray-900">
      <div className="flex justify-center flex-wrap relative">
        <div className="w-full md:w-40 py-4 sm:py-6 md:py-3 mx-10 order-none md:order-2 flex justify-center">
          <div className="w-32 sm:w-40 pt-2 md:pt-8 md:pb-1">
            <Link to="/">
              <Logo
                className="fill-white hover:fill-gray-900 stroke-white hover:stroke-gray-400 duration-500"
                css={css`
                  &:hover {
                    stroke-width: 2;
                  }
                `}
              />
            </Link>
          </div>
        </div>
        <nav className="flex items-center text-xs sm:text-base">
          <Navlink text="Ukuleles" to="/ukuleles" />
          <Navlink text="Process" to="/process" />
          <Navlink text="Maker" to="/maker" />
        </nav>
        <nav className="flex items-center text-xs sm:text-base md:order-3">
          <Navlink text="Wood" to="/wood" />
          <Navlink text="Extras" to="/extras" />
          <Navlink text="Reviews" to="/reviews" />
        </nav>
        <div className="absolute top-0 right-0 -mt-2 md:mt-0">
          <Navlink
            text={
              <span className="flex items-center w-full text-xs">
                <span className="pr-2 hidden md:inline-block">Contact</span>
                <FaEnvelope />
              </span>
            }
            to="/contact"
          />
        </div>
      </div>
    </header>
    <div className="text-center bg-gray-200 py-2 sm:py-3">
      <h2 className="text-sm sm:text-lg sm:tracking-widest">
        Handmade Ukuleles{' '}
        <span
          css={css`
            &:before,
            &:after {
              content: ' ~ ';
            }
          `}
        >
          NZ Native Timber
        </span>
        Te Pahu
      </h2>
    </div>
  </div>
)

export { Header }

import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import { Logo } from './svg/Logo'

import { scrollToTop } from '../helpers'
import { FaEnvelope } from 'react-icons/fa'

const Footer = () => (
  <footer className="bg-gray-900 text-white py-4 font-heading uppercase">
    <div className="w-32 pt-6 md:pt-8 md:pb-1 -mt-3 mx-auto flex-0">
      <Link to="/">
        <Logo
          className="fill-white hover:fill-gray-900 stroke-white hover:stroke-gray-400 duration-500"
          css={css`
            &:hover {
              stroke-width: 2;
            }
          `}
        />
      </Link>
    </div>
    <div className="text-sm flex flex-col-reverse sm:flex-row flex-wrap justify-center items-center mt-2 sm:pb-4">
      <a className="px-4 py-3 sm:py-0" href="#" onClick={scrollToTop}>
        &uarr; Back To Top
      </a>
      <p className="px-4">© {new Date().getFullYear()} Captain Ukuleles</p>
      <Link variant="secondary" className="mx-4  flex items-center justify-center" to="/contact">
        <FaEnvelope />
        <span className="inline-block ml-2 py-3 sm:py-0">Get in touch</span>
      </Link>
    </div>
  </footer>
)

export { Footer }
